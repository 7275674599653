@import "/src/styles/base/main";

.section {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1800px;
  padding: 0 3rem;
  width: 100%;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
  }
}

.img-container {
  height: 50vh;
  max-height: 600px;

  @include media-breakpoint-down(xl) {
    height: 40vh;
  }

  img {
    border-radius: 1rem;
    box-shadow: 0 2px 6px 0 #e6ecf2;
    object-fit: cover;
    object-position: top;
    width: 100%;
  }
}

.form {
  padding: 3rem;

  @include media-breakpoint-down(lg) {
    padding: 1rem 0;
    width: 100%;
  }

  a {
    color: #2c7ddc;
  }

  .input {
    max-width: 25rem;

    input {
      appearance: none;
      background-color: var(--primary);
      border: 2px solid #dadada;
      border-radius: 6px;
      box-sizing: border-box;
      color: var(--secondary);
      height: 50px;
      padding-left: 4rem;

      &::placeholder {
        color: var(--secondary);
        opacity: 1;
      }
    }

    .icon {
      font-size: 1.25rem;
      left: 1.5rem;
      position: absolute;
    }
  }

  button {
    height: 50px;
  }

  .error {
    color: #c00;
  }
}

.background {
  align-items: center;
  background-color: #f7fafb;
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 3rem;
  width: 100%;
}
