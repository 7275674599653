@import "/src/styles/base/main";

.wrapper {
  align-items: center;
  background:
    linear-gradient(180deg, rgb(0 0 0 / 45%) 0%, rgb(0 0 0 / 0%) 100%),
    linear-gradient(180deg, rgb(0 0 0 / 70%) 0%, rgb(0 0 0 / 0%) 100%),
    url("https://cdn.anivive.com/f_auto,q_auto/trial/library-with-big-window"),
    #d3d3d3 50% / cover no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  color: $white;
  display: flex;
  height: 600px;
  justify-content: center;
  max-width: 1800px;
  padding: 3rem;
  width: 100%;

  @include media-breakpoint-down(md) {
    height: fit-content;
  }
}

.section {
  max-width: 1400px;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }

  .logo {
    align-items: center;
    border-right: 2px solid #dcdcdc;
    display: flex;
    filter: brightness(0) invert(1);
    justify-content: center;
    padding: 1rem;

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #dcdcdc;
      border-right: 0;
      padding: 2rem 1rem;
    }

    &:last-child {
      border-bottom: 0;
      border-right: 0;
    }
  }
}
