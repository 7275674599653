@import "/src/styles/base/main";

.card {
  overflow: hidden;
  position: relative;
  transition: padding-bottom 0ms ease;
  width: 100%;

  .head {
    background-color: var(--primary);
    border-bottom: 2px solid;
    cursor: pointer;
    display: flex;
    padding: 1.5rem 0;
    position: relative;
    z-index: 1;

    .arrow {
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
      transition: 350ms ease;

      &.flipped {
        transform: rotate(-180deg);
      }
    }
  }

  .body {
    padding: 1.5rem 1rem;
  }

  &.transition {
    transition: padding-bottom 500ms ease;

    .body {
      bottom: 0;
      box-sizing: border-box;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 0;
    }
  }
}
