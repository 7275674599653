@import "/src/styles/base/main";

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px !important;
  padding-left: 5rem;
  padding-right: 5rem;
  position: relative;

  @include media-breakpoint-down(lg) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.cards {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(4, 25%);
  margin-bottom: 1.5rem;
  overflow: hidden;

  @include media-breakpoint-down(xxl) {
    grid-template-columns: repeat(3, 33%);
  }

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 50%);
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 100%);
  }

  .card {
    background-color: #fff;
    border: 2px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;
    max-width: 100%;
    padding: 1rem;
    transition: all 300ms ease-in-out;

    img {
      border-radius: 10px;
      height: 8rem;
      object-fit: cover;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 0.75rem;

      dd {
        display: none;
      }

      button {
        padding: 0;
      }

      img {
        height: 6rem;
      }
    }

    @include media-breakpoint-only(xs) {
      margin: 0.5rem;
      padding: 0.5rem;
    }

    &:hover {
      box-shadow: 5px 5px 0 0 var(--secondary);
    }
  }
}
