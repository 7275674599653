@import "/src/styles/base/main";

.container {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
  max-width: 1400px;

  @include media-breakpoint-down(lg) {
    pointer-events: 0;
  }

  .step {
    align-items: center;
    border-bottom: 2px solid #747474;
    cursor: pointer;
    display: flex;
    padding: 1.5rem 1rem;
    width: fit-content;

    @include media-breakpoint-down(lg) {
      border-bottom: 0;
      cursor: default;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 3rem;
      padding: 0;
      text-align: center;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    img {
      margin: 0 2rem 0 0;

      @include media-breakpoint-down(lg) {
        margin: 0 0 1rem;
      }
    }

    span {
      color: #747474;
      width: 2.75rem;

      @include media-breakpoint-down(lg) {
        color: #000;
      }
    }

    h4 {
      color: #747474;

      @include media-breakpoint-down(lg) {
        color: #000;
      }
    }

    &:hover {
      color: #404040;

      @include media-breakpoint-down(lg) {
        color: #000;
      }
    }

    &.active {
      border-color: #000;

      span {
        color: #000;
      }

      h4 {
        color: #000;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .image {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    img {
      object-fit: contain;
    }
  }
}
