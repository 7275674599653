@import "/src/styles/base/main";

.find-studies {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .map-container {
    aspect-ratio: 16 / 9;
    max-height: 27rem;
    max-width: 48rem;
    position: relative;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    max-width: 1400px;

    .cards {
      display: grid;
      grid-auto-rows: 0;
      grid-template-columns: repeat(3, 33.3333%);
      grid-template-rows: 1fr 1fr;
      overflow: hidden;

      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }

      .card {
        border: 2px solid;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        margin: 1rem;
        max-height: 10rem;
        padding: 1rem;
        transition: all 300ms ease-in-out;

        @include media-breakpoint-down(lg) {
          margin: 1rem 0;
        }

        img {
          aspect-ratio: 1 / 1;
          border-radius: 10px;
          margin-left: 2rem;
          max-height: 100%;
          object-fit: cover;
          width: unset;

          @include media-breakpoint-down(sm) {
            height: 4rem;
            margin-left: 1rem;
          }
        }

        dl {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        dd {
          margin: 0;
        }

        &:hover {
          box-shadow: 5px 5px 0 0 var(--secondary);
        }
      }
    }
  }

  input {
    appearance: none;
    background-color: var(--primary);
    border: 2px solid;
    border-radius: 6px;
    box-sizing: border-box;
    color: var(--secondary);
    height: 50px;
    padding-left: 3rem;

    &::placeholder {
      color: var(--secondary);
      opacity: 1;
    }
  }

  .icon {
    margin-left: 1rem;
    position: absolute;
  }
}
