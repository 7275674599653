@import "/src/styles/base/main";

.page {
  max-width: 100vw;

  // stylelint-disable-next-line
  @media (max-width: 2096px) {
    overflow: hidden;
  }
}

.section {
  box-sizing: border-box;
  max-width: 1800px;
  min-height: 100vh;
  padding: 3rem 1rem;
  width: 100%;

  button {
    padding: 0.75rem 1.5rem;
  }

  &.no-min {
    min-height: unset;
  }
}
