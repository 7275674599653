@import "/src/styles/base/main";

.hero {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1800px;
  min-height: 100vh;
  padding: 0 3rem;
  width: 100%;

  .images {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    overflow: hidden;
    width: 50%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    .card {
      border-radius: 50% 0;
      overflow: hidden;
      transition: all 300ms ease-in-out;

      &:nth-of-type(even) {
        transform: scaleX(-1);
      }

      img {
        aspect-ratio: 1 / 1;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &.image-0,
      &.image-8 {
        opacity: 0;
      }
    }
  }

  .button {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
