@import "/src/styles/base/main";

.wrapper {
  align-items: center;
  background:
    linear-gradient(180deg, rgb(0 0 0 / 40%) 0%, rgb(0 0 0 / 0%) 100%),
    linear-gradient(180deg, rgb(0 0 0 / 70%) 0%, rgb(0 0 0 / 0%) 100%),
    url("https://cdn.anivive.com/f_auto,q_auto/trial/two-dogs-playing"),
    #d3d3d3 0 -827.997px / 100% 459.425% no-repeat;
  background-position: center 55%;
  background-size: cover;
  box-sizing: border-box;
  color: $white;
  display: flex;
  height: 600px;
  justify-content: center;
  max-width: 1800px;
  padding: 3rem;
  width: 100%;

  @include media-breakpoint-down(md) {
    height: 400px;
    padding: 3rem 1.5rem;
  }
}

.section {
  height: 100%;
  max-width: 1400px;

  h3 {
    margin: 0 0 7.5rem;

    @include media-breakpoint-down(md) {
      margin: 0 0 5rem;
    }
  }

  a {
    width: fit-content;
  }

  button {
    padding: 0.75rem 1.5rem;
  }
}
